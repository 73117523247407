<template>
  <div class="equipment-document-detail">
    <van-nav-bar
      title="文档详情"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <div class="equipment-document-info">
      <div class="document-title">{{ info.name }}</div>
      <div class="document-type">{{ info.typeName }}</div>
      <div class="document-updatetime">更新时间: {{ info.updateTime }}</div>
    </div>
    <div class="equipment-document-box">
      <div class="document-desc">
        <div class="document-intro">文档简介</div>
        <p>{{ info.introduction || "暂无" }}</p>
      </div>
    </div>
    <div
      v-if="info.fileDTO"
      class="equipment-document-download"
      @click="downloadDocument"
    >
      <div class="download-button">
        <svg class="icon svg-icon" aria-hidden="true">
          <use xlink:href="#iconfont-wendang"></use></svg
        >下载文档
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {}
    };
  },
  created() {
    const params = this.$route.params;
    console.log("params.detail", params.detail);
    if (params.detail) {
      this.info = params.detail;
    } else {
      this.$router.back();
    }
  },
  methods: {
    downloadDocument() {
      if (!window.android.downLoadFile) {
        this.$toast.fail("此版本不支持导出，请更新后使用");
        return;
      }
      const [file] = this.info.fileDTO;
      const fileName = `${file.name}`;
      const fileUrl = window.globalConfig.VUE_APP_FILE_URL;
      const url = `${fileUrl}/file/download?downloadFlag=true&fileName=${file.id}`;
      window.android.downLoadFile(url, fileName);
    }
  }
};
</script>

<style lang="scss" scoped>
.equipment-document-detail {
  height: 100%;
  display: flex;
  flex-direction: column;
  .equipment-document-info {
    padding: 16px;
    .document-title {
      font-size: 16px;
      font-weight: 600;
    }
    .document-type {
      margin-top: 8px;
      font-size: 12px;
      color: #676767;
    }
    .document-updatetime {
      font-size: 12px;
      color: #aaaaaa;
      margin-top: 6px;
    }
  }
  .equipment-document-box {
    flex: 1;
    overflow-y: auto;
    height: 0;
    padding: 0 16px 16px 16px;
    box-sizing: border-box;
    .document-desc {
      height: 100%;
      background-color: #eceef2;
      border-radius: 4px;
      padding: 16px;
      box-sizing: border-box;
      .document-intro {
        font-weight: 700;
        margin-bottom: 16px;
      }
    }
  }
  .equipment-document-download {
    height: 48px;
    padding: 8px 24px;
    .download-button {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 24px;
      text-align: center;
      background-color: #1676ff;
      color: #fff;
      height: 100%;
      .icon {
        width: 20px;
        height: 20px;
        fill: currentColor;
        font-size: 30px;
        margin-right: 5px;
      }
    }
  }
}
</style>
